<template>
    <div class="certificateQuery_page">
        <BannerImg :bannerImgUrl="bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle title="果尔佳产业工人证书查询"> </CourseTitle>
            <div class="certificateQuery_div content">
                <div class="input_div flex">
                    <div class="list flex-center">
                        <div class="label">姓名</div>
                        <input type="text" v-model="params.name" placeholder="请输入姓名" />
                    </div>
                    <div class="list flex-center">
                        <div class="label">证书编号</div>
                        <input type="text" v-model="params.certificateNumber" placeholder="请输入证书编号" />
                    </div>
                    <div class="queryBtn display pointer" @click="queryBtn">查询</div>
                </div>
                <div class="certificateData flex" v-if="isShow">
                    <div class="userImg flex-center">
                        <img :src="formData.headPortraitUrl" alt="" />
                    </div>
                    <ul class="flex flex-wrap">
                        <li class="flex">
                            <div class="label display">姓&nbsp;&nbsp;&nbsp; 名：</div>
                            <div class="userInfo flex-center">{{ formData.name }}</div>
                        </li>
                        <li class="flex list">
                            <div class="label display">性 &nbsp;&nbsp;&nbsp;别：</div>
                            <div class="userInfo flex-center" v-if="formData.sex == 1">
                                男
                            </div>
                            <div class="userInfo flex-center" v-if="formData.sex == 2">
                                女
                            </div>
                        </li>
                        <li class="flex">
                            <div class="label display">工种名称：</div>
                            <div class="userInfo flex-center">
                                {{ formData.vocationalQualification }}
                            </div>
                        </li>
                        <li class="flex list">
                            <div class="label display">证书编号：</div>
                            <div class="userInfo flex-center">
                                {{ formData.certificateNumber }}
                            </div>
                        </li>
                        <li class="flex">
                            <div class="label display">发证日期：</div>
                            <div class="userInfo flex-center">{{ formData.openingDate }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- <div class="module2">
			<CourseTitle title="国家职业资格证书和职业技能等级证书查询">
			</CourseTitle>
			<div class="content">
				<div class="lists display">
					<div class="url_div">http://www.zscx.osta.org.cn/</div>
					<div @click="queryBtn" class="immediateInquiry display pointer">
						立即查询
					</div>
				</div>
			</div>
		</div> -->
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
export default {
    components: {
        BannerImg,
        CourseTitle,
    },
    data() {
        return {
            isShow: false,
            bannerImgUrl: '',
            params: {
                name: '',
                certificateNumber: '',
            },
            formData: {},
        }
    },
    mounted() {
        this.bannerImgUrl = this.$route.query.bannerImgUrl
    },
    methods: {
        queryBtn() {
            if (this.params.name == '') {
                return this.$message.error('请输入姓名')
            }
            if (this.params.certificateNumber == '') {
                return this.$message.error('请输入证书编号')
            }
            this.api.certificateQueryApi(this.params).then((res) => {
                if (res.code == 0) {
                    this.formData = res.data
                    this.isShow = true
                    this.params = {
                        name: "",
                        certificateNumber: "",
                    }
                } else {
                    this.formData = {}
                    this.isShow = false
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.certificateQuery_page {
    .module1 {
        height: 600px;
        background: url('../../assets/img/bg3.png') no-repeat;
        background-size: 100% 100%;

        .certificateQuery_div {
            margin-top: 53px;

            .input_div {
                padding-left: 100px;

                .list:nth-child(2) {
                    margin-left: 56px;
                }

                .list {
                    .label {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }

                    input {
                        width: 326px;
                        height: 48px;
                        background: #ffffff;
                        border: 1px solid #dddddd;
                        padding-left: 20px;
                        box-sizing: border-box;
                        margin-left: 30px;
                    }
                }

                .queryBtn {
                    width: 120px;
                    height: 48px;
                    background: #ff881e;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #f4f5f5;
                    margin-left: 20px;
                }
            }

            .certificateData {
                width: 1200px;
                height: 240px;
                background: white;
                margin-top: 28px;
                padding: 20px 31px 20px 25px;
                box-sizing: border-box;

                .userImg {
                    img {
                        width: 170px;
                        height: 200px;
                    }
                }

                ul {
                    height: 184px;
                    border: 1px solid #e2e2e2;
                    margin-left: 25px;

                    .list {
                        border-right: none !important;
                    }

                    li:nth-child(5) {
                        border-bottom: none !important;
                    }

                    li {
                        height: 60px;
                        border-bottom: 1px solid #dfdfdf;
                        border-right: 1px solid #dfdfdf;

                        .label {
                            width: 138px;
                            height: 60px;
                            background: #f5f5f5;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            padding-left: 34px;
                            border-right: 1px solid #dfdfdf;
                        }

                        .userInfo {
                            width: 334px;
                            height: 60px;
                            padding-left: 50px;
                        }
                    }
                }
            }
        }
    }

    .module2 {
        height: 380px;

        .lists {
            margin-top: 42px;

            .url_div {
                width: 309px;
                height: 48px;
                background: #ffffff;
                border: 1px solid #dddddd;
                border-radius: 5px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #5887ff;
                padding-left: 27px;
                line-height: 46px;
            }

            .immediateInquiry {
                width: 180px;
                height: 48px;
                background: #ff881e;
                border-radius: 5px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-left: 10px;
            }
        }
    }
}
</style>